<template>
  <div class="success_wrap">
    <div class="img">
      <img src="@/assets/icons/成功.png"
           alt="">
    </div>

    <div>您已成功激活学习卡</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.success_wrap {
  margin: 8vh auto;
  text-align: center;
  font-weight: 500;
  color: #333333;
  line-height: 23px;
  font-size: 20px;
  .img {
    width: 72px;
    height: 72px;
    // padding: 6px;
    margin: 2vh auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 710px) {
  .success_wrap {
    margin-top: 9vh;
  }
}
@media screen and (min-width: 1000px) {
  .success_wrap {
    margin-top: 12vh;
  }
}
</style>